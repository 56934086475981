import * as actionTypes from 'store/actions';

export const initialState = {
    user: null,
    rerender: 0
};

function getRandomIntInclusive(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.STORE_USER:
            return {
                ...state,
                user: action.user
            }
        case actionTypes.TRIGGER_RERENDER:
            return {
                ...state,
                rerender: getRandomIntInclusive(1, 2000000)
            }
        default:
            return state;
    }
};

export default reducer;