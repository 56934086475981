import React from 'react';

const button = (props) => (
    <button type={props.type} 
            onClick={props.clicked} 
            className={props.className ? props.className : "Button"}
            style={props.style}
            title={props.title}
            disabled={props.disabled}>{props.children}</button>
);

export default button;