import Button from 'components/UI/Button';
import Card from 'components/UI/Card';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import mustimg from 'assets/images/mustimg.jpg';
import forecastingimg from 'assets/images/forecasting.png';
import VerticalCardItem from 'components/UI/VerticalCardItem';

const MyApps = (props) => {
    const closeHandler = (<p className="x-button" 
                            style={{marginLeft: 10, marginTop: 5, marginBottom: 5}}
                            onClick={() => {props.deleteHandler()}}>{'\u2715'}
                            </p>);
    const settings = {
        className: "center",
        centerMode: true,
        infinite: true,
        centerPadding: "120px",
        slidesToShow: 1,
        speed: 500
    };
    return (
        <div className="MobileVerticalCardItemContainer" style={{display: "flex", justifyContent: "space-between", marginTop: 30}}> 
            <VerticalCardItem src={mustimg} link="https://mustd.lampsco.com/" id="consulting" title="Contratação ótima de MUST/D" text="Cálculo da contratação ótima de MUST/D de acordo com perfil de aversão a risco da distribuidora e regras regulatórias."></VerticalCardItem>
            <VerticalCardItem src={forecastingimg} link="https://forecasting.lampsco.com/" id="forecasting" title="Ferramenta de previsão" text="Nossa ferramenta possibilita previsão e análise exploratória de diferentes séries temporais comparando múltiplos modelos com o uso ou não de variáveis explicativas.

" left></VerticalCardItem>
        </div>
    );
}

export default MyApps;

// CAROUSSEL
// <div>
//     <Slider {...settings}>
//         <div>
//             <Card style={{marginTop: 0, marginBottom: 20, marginLeft: 40, marginRight: 40}}>
//                 <div style={{display: 'flex', flexDirection: "column", alignItems: "center"}}>
//                     <img style={{width: '80%', height: '100%'}} src={mustimg} alt="" />
//                     <h2 style={{marginTop: 10}}>MUST/D 1</h2>
//                     <p style={{marginTop: 0}}>Contratação ótima de MUST/D em sistemas radiais</p>
//                 </div>

//                 <div style={{display: "flex", justifyContent: "center"}}>
//                     <Button><a href="https://mustd.lampsco.com/" target="_blank">Abrir ferramenta</a></Button>
//                     <Button style={{marginLeft: 20}}>Consultar meus estudos</Button>
//                 </div>
//             </Card>
//         </div>
//         <div>
//             <Card style={{marginTop: 0, marginBottom: 20, marginLeft: 40, marginRight: 40}}>
//                 <div style={{display: 'flex', flexDirection: "column", alignItems: "center"}}>
//                     <img style={{width: '80%', height: '100%'}} src={mustimg} alt="" />
//                     <h2 style={{marginTop: 10}}>MUST/D 2</h2>
//                     <p style={{marginTop: 0}}>Contratação ótima de MUST/D em sistemas radiais</p>
//                 </div>

//                 <div style={{display: "flex", justifyContent: "center"}}>
//                     <Button>Abrir ferramenta</Button>
//                     <Button style={{marginLeft: 20}}>Consultar meus estudos</Button>
//                 </div>
//             </Card>
//         </div>
//         <div>
//             <Card style={{marginTop: 0, marginBottom: 20, marginLeft: 40, marginRight: 40}}>
//                 <div style={{display: 'flex', flexDirection: "column", alignItems: "center"}}>
//                     <img style={{width: '80%', height: '100%'}} src={mustimg} alt="" />
//                     <h2 style={{marginTop: 10}}>MUST/D 3</h2>
//                     <p style={{marginTop: 0}}>Contratação ótima de MUST/D em sistemas radiais</p>
//                 </div>

//                 <div style={{display: "flex", justifyContent: "center"}}>
//                     <Button>Abrir ferramenta</Button>
//                     <Button style={{marginLeft: 20}}>Consultar meus estudos</Button>
//                 </div>
//             </Card>
//         </div>
//     </Slider>
// </div>