import React, { useEffect } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import ScrollToTop from 'components/UI/ScrollToTop';
import Navbar from 'components/UI/Navbar';
import Landing from 'pages/Landing';
import Profile from 'pages/Profile';
import DeleteUser from 'pages/DeleteUser';
import { useAuth0 } from '@auth0/auth0-react';
import userAPI from 'apis/api';
import { connect } from 'react-redux';
import * as actionTypes from 'store/actions';

const Layout = (props) => {
    const { user } = useAuth0();
    useEffect(() => {
        if (user) {
            let formData = new FormData();
            formData.append("mail", user.email);
            formData.append("email_verified", user.email_verified);
            formData.append("name", user.name);
            formData.append("photo_url", user.picture);
            formData.append("locale", user.locale);
            userAPI.post('user/validate/', formData).then(response => {
                const userInfo = {
                    id: response.data.id,
                    sub: user.sub,
                    email: response.data.mail,
                    name: response.data.name,
                    company: response.data.company,
                    photo_url: response.data.photo_url,
                    locale: response.data.locale
                }
                props.setUser(userInfo);
            }).catch(error => {
                //   console.log(error);
            });
        }
    }, [user]);

    return (
    <>
        <Navbar />
        <div className="LayoutContainer">
            <ScrollToTop />
            <Switch>
                <Route path="/delete">
                    <DeleteUser />
                </Route>
                <Route path='/profile'>
                    <Profile />
                </Route>
                <Route path='/' exact>
                    <Landing />
                </Route>
            </Switch>
        </div>
        
        <main>{props.children}</main>
    </>
    );
}

const mapDispatchToProps = dispatch => {
    return {
        setUser: (user) => dispatch({type: actionTypes.STORE_USER, user: user}),
    }
};

export default connect(null, mapDispatchToProps)(Layout);