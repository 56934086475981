import React from 'react';

const CardLink = (props) => (
    <a style={{padding: 0, margin: 0, textDecoration: "none", width: "47%", cursor:"pointer"}} href={props.link}>
        <div className="cardhover" style={props.style}>
            {props.children}
            <div style={{display: 'flex', justifyContent: 'center'}}>
                <h2 className="first-label" 
                    style={{marginBottom: 0, 
                            display: props.inline ? "inline-block" : ""}}>
                    {props.title}
                </h2>
            </div>
        </div>
    </a>
);

export default CardLink;