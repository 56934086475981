import Card from 'components/UI/CardLink';

const VerticalCardItem = (props) => {
    return (
        <Card id={props.id} className="MobileVerticalCardItem" style={{display: "flex", flexDirection: "column", width: "100%", padding: 0}} link={props.link}>
            <img style={{height: "200px", borderTopLeftRadius: 20, borderTopRightRadius: 20, margin: 0}} src={props.src} alt="" />
            <hr style={{ border: "none", height: 1, color: "rgb(0, 11, 67)", backgroundColor: "rgb(0, 11, 67)", width: "100%", marginTop: 0, marginBottom: 0 }}/>
            <div style={{display: "flex", flexDirection: "column", paddingLeft: 30, paddingRight: 30, minHeight: 250}}>
                <h3 className="MobileSubtitle1" style={{fontWeight: 500, fontSize: "1.5em", marginBottom: 0}}><strong>{props.title}</strong></h3>
                <p className="MobileText" style={{fontSize: "20px", textAlign: "left"}}>{props.text}</p>
            </div>
        </Card>
    );
}

export default VerticalCardItem;