import { useState } from 'react';
import Button from 'components/UI/Button';
import Card from 'components/UI/Card';
import FileUploader from 'components/UI/FileUploader';
import { useEffect, useRef } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { ToastContainer, toast } from 'react-toastify';
import Modal from 'components/UI/Modal';
import userAPI from 'apis/api';
import { connect } from 'react-redux';
import { useAuth0 } from '@auth0/auth0-react';
import * as actionTypes from 'store/actions';

const validation = Yup.object().shape({
    email: Yup.string()
        .email('Email inválido')
        .required('Obrigatório'),
    fullName: Yup.string()
        .required('Obrigatório'),
    profilePicture: Yup.string()
});

const ProfileInfo = (props) => {
    const user = props.user;
    const formikRef = useRef();
    const [openModal, setOpenModal] = useState(false);
    const { logout } = useAuth0();
    useEffect(() => {}, [formikRef]);
    
    const completion = (message) => {
        toast.success(message);
    }
    const errorOcurred = (message) => {
        const options = {
            autoClose: false,
            hideProgressBar: false,
            position: toast.POSITION.TOP_RIGHT,
        };
        toast.error(message,options);
    }
    const imageUploadCompletion = (files) => {
        const file = files[0];
        let formData = new FormData();
        formData.append("user_id", user.id);
        formData.append("photo", file);
        userAPI.post('/user/upload_photo/', formData).then(response => {
            completion("Foto de perfil atualizada com sucesso");
            user.photo_url = response.data.photo_url;
            props.setUser(user);
            props.triggerRerender();
        }).catch(error => {
            errorOcurred("Houve um erro na atualização da foto de perfil");
        });
    }

    const deleteAccount = () => {
        let formData = new FormData();
        formData.append("user_id", user.id);
        formData.append("auth0_id", user.sub);
        userAPI.post('/user/delete/', formData).then(response => {
            logout({ returnTo: window.location.origin });
            setTimeout(function() {
                window.location.replace('/delete');
            }, 50);
        }).catch(error => {
            errorOcurred("Houve um erro ao deletar o usuário");
        });
    }

    return (
        <Formik
            initialValues={{
                email: user.email,
                fullName: user.name,
                profilePicture: user.photo_url
            }}
            validationSchema={validation}
            onSubmit={ values => {
                user.name = values.fullName;
                props.setUser(user);
                props.triggerRerender();
                
                let formData = new FormData();
                formData.append("user_id", user.id);
                // formData.append("mail", values.email);
                formData.append("name", values.fullName);
                
                userAPI.post('/user/update_user/', formData).then(response => {
                    completion("Perfil alterado com sucesso");
                }).catch(error => {
                    errorOcurred("Houve um erro na atualização do perfil");
                });
            }}
        >
            <Form>
                <ToastContainer />
                <div style={{display: "flex", flexDirection: "column", alignItems: "center", marginTop: 20}}>
                    <Card style={{width: "50%"}}>
                        <label style={{marginTop: 0}} htmlFor="email">Email</label>
                        <Field disabled name="email" type="text" placeholder="exemplo@exemplo.com" />
                        <ErrorMessage name="email" component="div" className={"error"} />

                        <label htmlFor="fullName">Nome completo</label>
                        <Field name="fullName" type="text" placeholder="John Doe" />
                        <ErrorMessage name="fullName" component="div" className={"error"} />

                        <label htmlFor="profilePicture">Foto de perfil</label>
                        <Field name="profilePicture" 
                                uploadName="profilePicture" 
                                component={FileUploader} 
                                completion={imageUploadCompletion} />
                        <ErrorMessage name="profilePicture" component="div" className={"error"} />

                        <div style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
                            <Button type="submit" style={{marginTop: "30px"}}>Salvar alterações</Button>
                        </div>
                    </Card>
                    <Button clicked={() => setOpenModal(true)} type="button" className="red-button">Excluir minha conta</Button>
                    <Modal show={openModal} modalClosed={() => setOpenModal(false)} style={{width: "50%", left: "25%", top: "10%"}}>
                        <div style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
                            <h2 style={{color: "rgba(254, 57, 36, 1)"}}>Tem certeza que deseja excluir a sua conta?</h2>
                            <div>
                                <Button clicked={() => setOpenModal(false)} type="button" className="blue-button">Cancelar</Button>
                                <Button clicked={deleteAccount} type="button" className="red-button" style={{marginLeft: 20}}>Excluir conta</Button>
                            </div>
                        </div>
                    </Modal>
                </div>
            </Form>
        </Formik>
    );
}

const mapStateToProps = state => {
    return {
        user: state.user,
        rerender: state.rerender
    }
};

const mapDispatchToProps = dispatch => {
    return {
        setUser: (user) => dispatch({type: actionTypes.STORE_USER, user: user}),
        triggerRerender: () => dispatch({type: actionTypes.TRIGGER_RERENDER})
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(ProfileInfo);
