import React, { useEffect } from 'react';

const Backdrop = (props) => {
    useEffect(() => {
        props.show && (document.body.style.overflow = 'hidden');
        !props.show && (document.body.style.overflow = 'unset');
     }, [props.show]);

    return (
        props.show ? <div className="Backdrop" onClick={props.clicked}></div> : null
    );
};

export default Backdrop;