import { useState } from 'react';
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import 'react-toastify/dist/ReactToastify.css';
import Loading from 'components/UI/Loading';
import ProfileInfo from 'components/forms/ProfileInfo';
import Button from 'components/UI/Button';
import MyApps from 'components/MyApps';
import { connect } from 'react-redux';

const Profile = (props) => {    
    const [openProfileInfo, setOpenProfileInfo] = useState(false);
    const [openMyApps, setOpenMyApps] = useState(false);

    let componentToDisplay = null;
    if (openProfileInfo) {
        componentToDisplay = <ProfileInfo user={props.user} deleteHandler={() => { setOpenProfileInfo(false) }}/>;
    } else if (openMyApps) {
        componentToDisplay = <MyApps deleteHandler={() => { setOpenMyApps(false) }}/>;
    }

    const toggleOpenProfileInfo = () => {
        if (!openProfileInfo) {
            setOpenMyApps(false);
        }
        setOpenProfileInfo(!openProfileInfo);
    }

    const toggleOpenMyApps = () => {
        if (!openMyApps) {
            setOpenProfileInfo(false);
        }
        setOpenMyApps(!openMyApps)
    }

    return (
        <>
        {
            props.user ?
            <div>
                <div style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
                    { 
                        props.user.photo_url && <img src={props.user.photo_url} alt="Profile" className="user"/>
                    }
                    <h2>{props.user.name}</h2>
                </div>
                
                <div style={{display: "flex", justifyContent: "center", marginTop: 10}}>
                    <Button style={{backgroundColor: openMyApps ? "rgba(254, 121, 56, 1)" : "white", 
                                    color: !openMyApps ? "rgba(254, 121, 56, 1)" : "white"}} 
                            clicked={toggleOpenMyApps}>
                        Minhas aplicações
                    </Button>
                    <Button style={{marginLeft: 20, 
                                    backgroundColor: openProfileInfo ? "rgba(254, 121, 56, 1)" : "white",
                                    color: !openProfileInfo ? "rgba(254, 121, 56, 1)" : "white"}} 
                                    clicked={toggleOpenProfileInfo}>
                        Meu Perfil
                    </Button>
                </div>
                { componentToDisplay } 
            </div> :
            <Loading />
        }
        </>
    );
}

const mapStateToProps = state => {
    return {
        user: state.user,
        rerender: state.rerender
    }
};

export default withAuthenticationRequired(connect(mapStateToProps)(Profile), {
    onRedirecting: () => <Loading />,
});
