import React from 'react';
import lampstools from 'assets/images/lampstools.svg';
import mustimg from 'assets/images/mustimg.jpg';
import forecastingimg from 'assets/images/forecasting.png';
import VerticalCardItem from 'components/UI/VerticalCardItem';

const Landing = (props) => {
    return (
        <div>
            <div style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
                <img style={{padding: 10, marginTop: 30, marginBottom: 30, width: '30%', height: '100%'}} src={lampstools} alt="" />
                {/* <p style={{fontSize: "2.0em", marginTop: 0, marginBottom: 0}}><b>Lorem ipsum dolor sit amet, consectetur adipiscing elit</b></p>
                <p style={{fontSize: "2.0em", marginTop: 0, marginBottom: 20}}><b>sed do eiusmod tempor incididunt ut labore et dolore</b></p> */}
            </div>
            <hr style={{ border: "1px solid rgb(254, 121, 56)" }}/>
            <div className="MobileVerticalCardItemContainer" style={{display: "flex", justifyContent: "space-between", marginTop: 30}}> 
                <VerticalCardItem src={mustimg} link="https://mustd.lampsco.com/" id="consulting" title="Contratação ótima de MUST/D" text="Cálculo da contratação ótima de MUST/D de acordo com perfil de aversão a risco da distribuidora e regras regulatórias."></VerticalCardItem>
                <VerticalCardItem src={forecastingimg} link="https://forecasting.lampsco.com/" id="forecasting" title="Ferramenta de previsão" text="Nossa ferramenta possibilita previsão e análise exploratória de diferentes séries temporais comparando múltiplos modelos com o uso ou não de variáveis explicativas." left></VerticalCardItem>
            </div>
        </div>
    );
};

export default Landing;